<template>
  <div>
    <van-nav-bar
      title="种植档案"
      left-text="返回"
      right-text="添加档案"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div>
      <div
        class="FarmlandList"
        v-for="(item, index) in archivesList"
        :key="index"
         @click="toDetail(item.id)"
      >
        <van-swipe-cell>
          <div class="FarmlandList-box">
            <div class="FarmlandList-box-left">
              <div class="FarmlandList-box-left-img">
                <img
                  :src="item.imageUrl[0]"
                  style="width: 100%; height: 100%"
                />
              </div>
              <div class="FarmlandList-box-left-word">
                <div class="FarmlandList-box-left-title-name">
                  {{ item.name }}
                </div>
                <div class="location-name">
                  {{ item.province_name }}-{{ item.city_name }}-{{
                    item.county_name
                  }}
                </div>
              </div>
            </div>
            <div class="FarmlandList-box-right">
              <div class="FarmlandList-box-right-area">{{ item.count }} 颗</div>
            </div>
          </div>
          <template #right>
            <van-button
              square
              type="primary"
              text="编辑"
              @click="editPenLand(item.id)"
              class="farmlandBtn"
            />
            <van-button
              square
              type="danger"
              text="删除"
              @click="deletePenLand(item.id)"
              class="farmlandBtn"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, SwipeCell, Button, Notify } from "vant"
import { getListAll, postDelete } from "../../service/archives/archives"
export default {
  props: {},
  components: {
    VanNavBar: NavBar,
    VanSwipeCell: SwipeCell,
    VanButton: Button,
    [Notify.Component.name]: Notify.Component
  },
  data() {
    return {
      archivesList: [],
      current: 1,
      size: 10
    }
  },
  methods: {
    // 返回按钮，返回上一级
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      this.$router.push("/addArchives")
    },
    // 初始化数据
    init() {
      getListAll(this.size, this.current).then((res) => {
        this.archivesList = res.data.records
      })
    },
    // 删除
    async deletePenLand(id) {
      const params = {
        id: id
      }
      const res = await postDelete(params)
      if (res.code === 200) {
        Notify({ type: "success", message: "删除成功" })
        this.init()
      } else {
        Notify({ type: "error", message: "删除失败" })
      }
    },
    // 编辑
    editPenLand(id) {
      this.$router.push(`/EditArchives/${id}`)
    },
    // 种植详情页面
    toDetail(id) {
      this.$router.push(`/archivesDetails/${id}`)
    }
  },
  mounted() {
    this.init()
  },
  watch: {},
  computed: {},
  filters: {}
}
</script>

<style scoped lang='scss'>
.goods-card {
  margin: 0;
  background-color: #fff;
}

.farmlandBtn {
  height: 100%;
}
.FarmlandList {
  margin-top: 10px;
  margin-bottom: 10px;
  .FarmlandList-box {
    display: flex;
    height: 58px;
    .FarmlandList-box-left {
      display: flex;
      .FarmlandList-box-left-img {
        width: 70px;
        height: 60px;
        flex-shrink: 0;
      }
      .FarmlandList-box-left-word {
        margin-top: 5px;
        margin-left: 5px;
        flex-shrink: 0;
        .FarmlandList-box-left-title-name {
          width: 50.4vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .location-name {
          margin-top: 16px;
          width: 189px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .FarmlandList-box-right {
      .FarmlandList-box-right-word {
        display: flex;
        .farmland-type {
          position: absolute;
          left: 188px;
          top: 4px;
        }
        .farmland-cycle {
          margin-top: 5px;
          position: absolute;
          right: 14px;
          bottom: 28px;
          background: #001f90;
          color: white;
          font-size: 14px;
          padding: 5px;
        }
      }
      .FarmlandList-box-right-area {
        position: absolute;
        right: 5.33333vw;
        bottom: -0.46667vw;
      }
    }
  }
}
</style>